import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import './index.scss';
import pathJson from "../../data/common/path.json"

const MerchantPage = () => {

  return(
    <Layout>
    <Seo title="商家" keywords={'矩阵营销,矩阵运营,品牌营销,品牌推广,软文推广,品牌建造,降本增效,内容输出,品牌运营,软文,软文营销,新媒体运营,新媒体营销,内容运营,内容代发,内容投放,全民营销,集客营销,数字营销,内容协同,全员营销,口碑营销,营销策划,增长运营,运营方案,社交媒体营销,数字化营销,口碑获客,内容管理平台,故事营销,线上经济,全媒体运营,自媒体运营,品牌爆火,一夜爆红,媒介矩阵,媒体投放,品牌内容撰写推广,口碑打造,裂变传播,私域流量,内容协调,数智营销,整合营销,社交营销,用户价值,用户喜好/偏好,矩阵号'}/>
    <div id="merchantStories" className="pt-5 bg-dark text-light">
      <div className="container my-text-mobile-left">
        <h2 className="text-center my-5">
          {/* Merchant-stories */}
          商家案例
        </h2>
        <div className="row">
          <div className="col-sm-12">
            <div className="card my-3 border-0">
              <div className="row g-0 bg-dark">
                <div className="col-sm-4">
                  <StaticImage src={"../../assets/images/merchant_stories/electronic_product.png"} className="img-fluid w-100 d-none d-sm-block merchant-stories-image" alt={""}></StaticImage>
                </div>
                <div className="col-sm-8">
                  <div className="card-body bg-dark">
                    <h5 className="card-title">
                      {/* Electronic product sales team */}
                      电子产品销售团队
                    </h5>
                    <p className="card-text">
                      {/* There are too many categories to choose from on traditional
                    e-commerce platforms. The market is surely huge, but the competition is also
                    fierce. For niche brands, sales on traditional e-commerce platforms are not
                    optimistic. ContentWork provides exclusive channels for different categories of
                    light industrial products, and the creators are promoting our products and
                    consumers of our products. Through content marketing, we quickly gained a large
                    number of loyal customers and brand influence. I hope that ContentWork will
                    develop better and better, and cooperation will be win-win. */}
                      传统电商平台有太多品类可供选择。 市场肯定很大，但是竞争也很激烈。 对于小众品牌来说，传统电商平台的销量并不乐观。 里德笔记为不同品类的轻工产品提供专属渠道，创作者为我们宣传产品、触达我们的消费者。 通过内容营销，可以迅速获得大量忠实客户和提升品牌影响力。 希望里德笔记帮助您发展的越来越好，合作共赢。
                    </p>
                    <p className="card-text">
                      <small className="text-primary">
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-3 border-0">
                <div className="row g-0 bg-dark">
                  <div className="col-sm-8">
                    <div className="card-body bg-dark">
                      <h5 className="card-title">
                        {/* Life products maker */}
                        生活用品制造商
                      </h5>
                      <p className="card-text">
                        {/* We are very confident in the products we create, but it is
                        difficult for emerging brands to build awareness quickly. ContentWork has
                        brought great help to our brand marketing. The creators on this platform
                        have created lots of high-quality marketing content for us, so we have
                        obtained many sales leads and target customers. Thanks to ContentWork for
                        bringing us better development. */}
                        我们对自己创造的产品很有信心，但新兴品牌很难快速建立知名度。 里德笔记给我们的品牌营销带来了很大的帮助。 这个平台的创作者为我们创造了很多优质的营销内容，因此我们获得了很多销售线索和目标客户。 感谢 里德笔记 为我们带来更好的发展。
                      </p>
                      <p className="card-text">
                        <small className="text-primary">
                          <i className="bi bi-star-fill"></i>
                          <i className="bi bi-star-fill"></i>
                          <i className="bi bi-star-fill"></i>
                          <i className="bi bi-star-fill"></i>
                          <i className="bi bi-star-fill"></i>
                        </small>
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <StaticImage src="../../assets/images/merchant_stories/life_product_maker.png"
                      className="img-fluid w-100 d-none d-sm-block merchant-stories-image" alt="..."></StaticImage>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card mb-3 border-0">
              <div className="row g-0 bg-dark">
                <div className="col-sm-4">
                  <StaticImage src="../../assets/images/merchant_stories/cosmetics_manufacturer.png"
                    className="img-fluid w-100 d-none d-sm-block merchant-stories-image" alt="..." />
                </div>
                <div className="col-sm-8">
                  <div className="card-body bg-dark">
                    <h5 className="card-title">
                      {/* Cosmetics manufacturer */}
                      化妆品制造商
                    </h5>
                    <p className="card-text">
                      {/* The competition in the cosmetics industry is really fierce, and
                      product marketing is a top priority, so we urgently need a mature marketing
                      program. In the end, we decided to try the way ContentWork offered, and the
                      result was unexpectedly good, not only did many people buy our products to
                      become our new customers, but also attracted the attention of a bunch of
                      potential customers. We all believe that this platform is a platform with great
                      potential and will develop better in the future, so we will continue to
                      cooperate with the platform. */}
                      化妆品行业的竞争真的很激烈，产品营销是重中之重，所以我们迫切需要一个成熟的营销方案。 最后，我们决定尝试一下里德笔记提供的方式，结果出乎意料的好，不仅很多人购买了我们的产品，成为我们的新客户，还吸引了一批潜在客户的关注。 我们都相信这个平台是一个潜力巨大的平台，未来会发展得更好，所以我们会继续与里德笔记合作。
                    </p>
                    <p className="card-text">
                      <small className="text-primary">
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-3 border-0">
                <div className="row g-0 bg-dark">
                  <div className="col-sm-8">
                    <div className="card-body bg-dark">
                      <h5 className="card-title">
                        {/* Real Estate Marketing Department */}
                        房地产营销部
                      </h5>
                      <p className="card-text">
                        {/* As a real estate industry, we have been conducting offline
                        transactions through intermediaries or referrals, but online promotion is
                        relatively rare. ContentWork was recommended to us by our leaders. As long
                        as we provide some information such as office building pictures and prices,
                        ContentWork can provide us with a large number of customer leads, and most
                        of the submitted leads are valid leads with a high transaction rate.
                        ContentWork has relieved our performance pressure and provided us with more
                        resources and clues. Thank you very much, we will continue to maintain a
                        good cooperative relationship with the platform in the future. */}
                        作为房地产行业，我们一直通过中介或推荐进行线下交易，但线上推广相对较少。 里德笔记是我们的领导推荐给我们的。 只要我们提供一些写字楼图片、价格等信息，里德笔记就会为我们提供大量的潜在客户，而且这些潜在客户大多是有效的潜在客户，成交率很高。 里德笔记 缓解了我们的绩效压力，为我们提供了更多的资源和线索。 非常感谢里德笔记，未来我们将继续与平台保持良好的合作关系。
                      </p>
                      <p className="card-text">
                        <small className="text-primary">
                          <i className="bi bi-star-fill"></i>
                          <i className="bi bi-star-fill"></i>
                          <i className="bi bi-star-fill"></i>
                          <i className="bi bi-star-fill"></i>
                          <i className="bi bi-star-fill"></i>
                        </small>
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <StaticImage src="../../assets/images/merchant_stories/real_estate.png"
                      className="img-fluid w-100 d-none d-sm-block merchant-stories-image" alt="..." />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card mb-3 border-0">
              <div className="row g-0 bg-dark">
                <div className="col-sm-4">
                  <StaticImage src="../../assets/images/merchant_stories/housekeeping_company.png"
                    className="img-fluid w-100 d-none d-sm-block merchant-stories-image" alt="..." />
                </div>
                <div className="col-sm-8">
                  <div className="card-body bg-dark">
                    <h5 className="card-title">
                      {/* Housekeeping company */}
                      家政公司
                    </h5>
                    <p className="card-text">
                      {/* Our company has been cooperating with ContentWork for a long
                      time, and this is the best proof. As a chain housekeeping company, we have
                      always had a high demand for housekeeping staff, but the number of housekeeping
                      staff we have is far from keeping up with the number of customers, and the
                      housekeeping staff usually do not only serve one housekeeping company, so we
                      chose ContentWork to find housekeeping staff to provide housekeeping services to
                      our customers. The housekeeping channel of ContentWork allows us to directly
                      connect with a large number of housekeepers, allowing us to find the favorite
                      housekeeper for employers quickly. Thanks a lot for this platform. */}
                      我们公司与里德笔记合作已久，这就是最好的证明。 作为一家连锁家政公司，我们对家政人员的需求一直很高，但我们的家政人员数量远远跟不上客户的数量，而且家政人员通常不只为一家家政公司服务，所以我们选择了里德笔记来寻找家政人员为我们的客户提供家政服务。 里德笔记的家政频道让我们可以直接与海量的管家对接，让我们快速找到雇主心仪的管家。 非常感谢这个平台。
                    </p>
                    <p className="card-text">
                      <small className="text-primary">
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-3 border-0">
                <div className="row g-0 bg-dark">
                  <div className="col-sm-8">
                    <div className="card-body bg-dark">
                      <h5 className="card-title">
                        {/* Smart home industry */}
                        智能家居行业
                      </h5>
                      <p className="card-text">
                        {/* Our company is mainly engaged in smart home products. The
                        smart home market has always attached great importance to brand effect, and
                        the promotion effect is always minimal. We encountered ContentWork in the
                        period of promotion bottleneck. Help us easily build a good reputation and
                        improve brand awareness. At the same time, the improvement of brand
                        awareness has also brought us a large number of orders. If you are looking
                        for a platform that can help you improve your brand awareness, there is
                        absolutely nothing wrong with ContentWork! */}
                        我们公司主要从事智能家居产品。 智能家居市场历来重视品牌效应，推广效果始终微乎其微。 我们在推广瓶颈期遇到了里德笔记。 帮助我们轻松建立良好的声誉并提高品牌知名度。 同时，品牌知名度的提升也为我们带来了大量的订单。 如果您正在寻找一个可以帮助您提高品牌知名度的平台，那么 里德笔记 绝对没有错！
                      </p>
                      <p className="card-text">
                        <small className="text-primary">
                          <i className="bi bi-star-fill"></i>
                          <i className="bi bi-star-fill"></i>
                          <i className="bi bi-star-fill"></i>
                          <i className="bi bi-star-fill"></i>
                          <i className="bi bi-star-fill"></i>
                        </small>
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <StaticImage src="../../assets/images/merchant_stories/smart_home.png"
                      className="img-fluid w-100 d-none d-sm-block merchant-stories-image" alt="..."></StaticImage>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="commingSoon" className="pt-5 pb-5 bg-dark text-light">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-6">
            <h2>
              {/* Coming soon */}
              亲爱的用户
            </h2>
            <br />
            <p>
              {/* The system is under development, thank you for your attention to us. If you have any
              requirements and suggestions, please email us: bd@leadingcloud.xyz */}
              平台在持续优化中，感谢您对我们的关注。
              <br />
              如果您有任何要求和建议，请发邮件给我们：bd@leadingcloud.xyz
            </p>
            <a href="mailto:bd@leadingcloud.xyz" className="btn btn-light mt-3">
              <i className="bi bi-chevron-right"></i>
              {/* Email us */}
              联系我们
            </a>
          </div>
        </div>
      </div>
    </div>
    <div id="contentMarketing" className="pt-5 pb-5">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-md">
            <StaticImage src="../../assets/images/merchant/social_analytics.webp" className="img-fluid" alt="" />
          </div>
          <div className="col-md my-text-mobile-left">
            <h2>
              {/* Content marketing platform */}
              内容营销平台
            </h2>
            <p className="lead">
              {/* ContentWork is a content marketing platform with result-oriented and task-based, data-driven. It
              is valuable for integrating marketing, matrix marketing, SEO, employees collaboration, employees
              advocacy, brand promotion, product promotion, etc. */}
              里德笔记是一个以结果为导向、以任务为基础、数据驱动的内容营销平台。 对整合营销、矩阵营销、SEO、员工协作、员工宣传、品牌推广、产品推广等具有重要价值。
            </p>
            <p>
              {/* Merchant center has the ability for the whole content marketing process such as project
              management, campaign management, task creation, task and order audit, payment management,
              cash-out management for business. */}
              商家中心具备项目管理、活动管理、任务创建、任务订单审核、支付管理、业务提现管理等内容营销全流程的能力。
            </p>
            <p>
              {/* Part-time creator users or your staff can claim tasks after joining a channel, and merchants pay
              the creator by task. Creator users can download the App(android only now) to join the channels.
              There is also a desktop web application for a creator to write an article and submit
              article-like tasks. */}
              创作者用户或您的员工加入频道后可以领取任务，商家按任务向创作者付费。 创作者用户可以下载应用程序（仅限安卓）加入频道。 还有一个网页端创作者中心供创作者撰写文章并提交类似文章的任务。
            </p>
            <a href="/tag/blog" className="btn btn-light mt-3" style={{background:'#576B95',color:'#fff'}}>
              <i className="bi bi-chevron-right"></i>
              {/* Learn More */}
              了解更多
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  )
}
export default MerchantPage;
